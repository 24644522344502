import React, { Component } from 'react';

class Footer extends Component{
    render(){
        return(
            <>
            <footer>
               <div className="" style={{background: '#737070'}}>
                 <div className="container">
                   <div className="ft-widget-area footer_main_div">
                       <div className="row">
                           <div className="col-md-3 col-sm-6">
                           <div className="ft-area1">
                                <div className="swin-widget swin-widget-about">
                                    <div className="clerfix">
      <div className="header-logo"><img src="./assets/images/logo/sky_logo_new.png " alt="the-chef" className="logo-img site_logo" /></div>
                                    
                                        </div>
                                 </div>
                            </div>            
                           </div>

                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    
                           <div className="col-md-6 col-sm-6">
                                <div className="ft-area1">
                                 <div className="swin-widget widget-about">
                                    <div className="title-widget footer_heading">SKY CAFÉ </div>
                                    <div className="about-contact-info clearfix">
                                        <div className="info-content phone-content">
                                        <div className="info-icon"><i className="fa-icon fa fa-phone"></i></div>
                                        <div className="info-text">
                                            <p>3-1403</p>
                                        </div>
                                        </div>
                                        <div className="info-content email-content">
                                        {/* <div className="info-icon"><i className="fa-icon fa fa-envelope"></i></div> */}
                                        <div className="info-text">
                                            {/* <p>lobbyreception.ph@jpmchase.com</p> */}
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                               </div>
                           </div>
                           <div className="col-md-6 col-sm-6">
                                <div className="ft-area1">
                                 <div className="swin-widget widget-about">
                                    <div className="title-widget footer_heading">SKY café MANAFER – SAYO USUKI</div>
                                    <div className="about-contact-info clearfix">
                                        <div className="info-content phone-content">
                                        <div className="info-icon"><i className="fa-icon fa fa-phone"></i></div>
                                        <div className="info-text">
                                            <p>3-1407</p>
                                        </div>
                                        </div>
                                        <div className="info-content email-content">
                                        <div className="info-icon"><i className="fa-icon fa fa-envelope"></i></div>
                                        <div className="info-text">
                                            <p>sayo.usuki@jpmchase.com</p>
                                            
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                               </div>
                           </div>

                                </div>
                            </div>
                            <hr/>

                            <div className="row">
                                <div className="col-md-12">
                                <div className="col-md-6 col-sm-6">
                                <div className="ft-area1">
                                 <div className="swin-widget widget-about">
                                    <div className="title-widget footer_heading">Amenity Services Manager: </div>
                                    <div className="about-contact-info clearfix">
                                        <div className="info-content phone-content">
                                        <div className="info-icon"><i className="fa-icon fa fa-phone"></i></div>
                                        <div className="info-text">
                                            <p>3-6729 </p>
                                        </div>
                                        </div>
                                        <div className="info-content email-content">
                                        <div className="info-icon"><i className="fa-icon fa fa-envelope"></i></div>
                                        <div className="info-text">
                                            <p>hiromi.tometaka@jpmorgan.com</p>
                                            
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                               </div>
                           </div>

                           {/* <div className="col-md-6 col-sm-6">
                                <div className="ft-area1">
                                 <div className="swin-widget widget-about">
                                    <div className="title-widget footer_heading">Pantry Services</div>
                                    <div className="about-contact-info clearfix">
                                        <div className="info-content phone-content">
                                        <div className="info-icon"><i className="fa-icon fa fa-phone"></i></div>
                                        <div className="info-text">
                                            <p>Coming Soon</p>
                                        </div>
                                        </div>
                                        <div className="info-content email-content">
                                        <div className="info-icon"><i className="fa-icon fa fa-envelope"></i></div>
                                        <div className="info-text">
                                            <p>employeedining.ph@jpmchase.com </p>
                                            

                                        </div>
                                        </div>
                                    </div>
                                 </div>
                               </div>
                           </div> */}

                                </div>
                            </div>
                        </div>

                           
                       </div>
                   </div>
                 </div>
              </div>
            </footer>  
            </>
        );
    }
}

export default Footer;
