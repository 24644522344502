import React,{Component} from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './BaseUrl';
import Slider from "react-slick";
import {Link} from 'react-router-dom';


class Employee_dinning extends Component {

  constructor(){
    super();
    this.state={
      meal_timings:[],
      banner_img:[],
      base_url:global.BaseUrl,
      assets_url:global.AssetsUrl,
      lobby_level_data:[],
      lobby_level_meal:[],
      level_one:[],
      level_two:[],
      counter_meal:[],
      relatedoc:[],
      level_heading1:[],
      level_heading2:[],

    }
  }

  componentDidMount(){

    fetch(`${this.state.base_url}/getAllMealtypes`).then((resp)=>{
      resp.json().then((result)=>{
      this.setState({ meal_timings: result});
        
      })
    });

    fetch(`${this.state.base_url}/getbylobbylevel`).then((resp)=>{
      resp.json().then((result)=>{
        // console.warn(result['id']);
        this.setState({ lobby_level_data: result});
        // this.setState({ lobby_level_meal: result.meal_counter});
        
        
      })
    });

    // fetch(`${this.state.base_url}/getbylevel1`).then((resp)=>{
    //   resp.json().then((result)=>{
    //     // console.warn(result);
    //     this.setState({ level_one: result});
        
    //   })
    // });

    // fetch(`${this.state.base_url}/getbylevel2`).then((resp)=>{
    //   resp.json().then((result)=>{
    //     // console.warn(result);
    //     this.setState({ level_two: result});
        
    //   })
    // });

    fetch(`${this.state.base_url}/AllLevels/2`).then((resp)=>{
      resp.json().then((result)=>{
        // console.warn(result);
        this.setState({ level_heading1: result});
        
      })
    });
    fetch(`${this.state.base_url}/AllLevels/3`).then((resp)=>{
      resp.json().then((result)=>{
        // console.warn(result);
        this.setState({ level_heading2: result});
        
      })
    });

    fetch(`${this.state.base_url}/getfilesbyid/9`).then((resp)=>{
      resp.json().then((result)=>{
        this.setState({ relatedoc: result});        
      })
    });

    fetch(`${this.state.base_url}/getBannerById/9`).then((resp)=>{
      resp.json().then((result)=>{
         console.log(result);
        this.setState({ banner_img: result.banner_images});
        // this.setState({ headingType: result.data});           
      })
    });

  }

  render(){
    const {meal_timings,lobby_level_data,banner_img,lobby_level_meal,level_heading2,level_heading1,level_one,level_two,counter_meal,relatedoc,lobby_counter_id} = this.state;
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    // console.warn(lobby_level_data);
    var settings = {
      className: "",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: 'linear',
      adaptiveHeight: true
      
    };

   
  return (
    <>
  

      {/* <div className="container-fluid slider_top">
            <div className="row">
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/employeedining1.jpg" alt="" className='bnr_img_30 padding_set' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/employeedining3.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/employeedining2.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
            </div>
      </div> */}

      <div className="container-fluid slider_top">
            <div className="row">
              {banner_img.map((banner,i)=>{
                return(
                  <div className="col-md-4 col-12">
                <img src={`${this.state.assets_url}/uploads/${banner}`} alt="" className='bnr_img_30 padding_set' />
              </div>
                )
              })}
            </div>
      </div>
      

     <section className="section-reservation-form" style={{marginTop:'20px'}}>
       <div className="container">
         <div className="row">
           <div className="col-md-12">
             <div className="swin-sc swin-sc-title style-2">
             </div>
             <div className="ft-fixed-area">
               <div className="reservation-box diningmenu row">
                 <div className="reservation-wrap">
                    <p className="text_justify" style={{color:'#616f7d',fontSize:'18px'}}>
                    Our employee dining services provides a range of options to refuel and rejuvenate. Scroll down to embark on your gastronomic journey.
                      </p>
                    <p className="text_justify" style={{color:'#616f7d',fontSize:'18px'}}>The Sky Cafe is located on the 32nd floor of the Tokyo Building and provides freshly brewed coffee, baked goods 
and health focused offerings such as smoothies, salad and fresh fruits. There are also several lunch box options 
to choose from. Savour the offerings with a great view of the city!</p>
                    <h3 className="res-title" style={{textAlign:'Left',color:'#000',textAlign:'center',fontSize:'17px',marginTop:'40px'}}><u>Operating Hours</u></h3>

                    <div className="res-date-time">

                     <div className="container" style={{marginTop:'15px'}}>
                     <div className="row d-center">
                     {
                            meal_timings.map(meal_time => 
                              <div className="col-md-3 col-12 meal_box">
                                <span className="meal_heading">{meal_time.type_name}</span> <br/>
                                <span className="meal_content" style={{color:'#616f7d',fontSize:'17px',fontWeight:'700'}}>{meal_time.start_time}-{meal_time.end_time}  </span>                              

                              </div>
                              )
                          }
                      </div>
                     </div>
                         
                    </div>
                 </div>
               </div>
             </div>
            
             <div className="swin-sc swin-sc-title style-3 light" style={{marginTop:'40px'}}>
              <p className="title"><span></span></p>

             
          </div>
         {
           lobby_level_data.map(level =>
            <div>
            
            <div className="row diningmenu row-center">
            <div className="col-md-3 col-sm-6 col-xs-12">
            <img src={`${this.state.assets_url}/uploads/${level.counter_logo}`} alt="fooday" className="img img-responsive"/>
            </div>
            <div className="col-md-9 col-sm-6 col-xs-12">
               <div className="product-summary">
               {/* <div className="title">{level.counter_name}</div> */}
               <div className="product-desc">
                        <p>{renderHTML(level.counter_description)}</p>
                      </div>
                      <div className="product-info">
                                <ul className="list-inline" style={{marginLeft:'-11px'}}>
                                {
                                   level.counter_timings == null ? null : <li className="author"><span className="text">{level.counter_timings}</span></li> 
                                  //  level.counter_timings == null ? null : <li className="author"><span style={{fontWeight:'bold'}}>Operational Timings</span>-<span className="text">{level.counter_timings}</span></li> 
                                 }
                                  {
                                   level.counter_timings == null ? null : <li className="author author_right"><span>{level.meal_type}</span></li>
                                 }
                                 {/* <li>{level.meal_type}</li> */}
          
                                </ul><br/>
                                
                                {/* <div className="swin-btn-wrap"><a href={`/CounterMenu/${level.id}`} className="swin-btn"><span>View Menu</span></a></div> */}
                                {
                                 level.counter_status == 1 ? <div className="swin-btn-wrap"><Link to={`/CounterMenu/${level.id}`} className="swin-btn"><span>View Menu</span></Link></div> : null
                                }
                              </div>     
               </div>
            </div>
          </div>
          </div>
            )
         }
         
          {/* <div className="swin-sc swin-sc-title style-3 light" style={{marginTop:"70px"}}>
          {
            level_heading1.map(heading=>
              <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
            <img src={`assets/images/logo/${heading.level_image}`} className="heading_logo" alt="" style={{height:"118px"}}/>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12r " style={{height:"118px",position:"relative",textAlign:"center"}}>
              <p className="title" style={{textAlign:"center",lineHeight:"2.5",display:"inline-block",verticalAlign:"middle"}}><span>{heading.level_name}</span></p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
              </div>
            </div>  
            )
          }
          </div> */}


          {/* {
           level_one.map(level =>
            
            <div className="row diningmenu">
            <div className="col-md-4 col-sm-6 col-xs-12">
            <img src={`${this.state.assets_url}/uploads/${level.counter_logo}`} alt="fooday" className="img img-responsive"/>
            </div>
            <div className="col-md-8 col-sm-6 col-xs-12">
               <div className="product-summary">
               <div className="title">{level.counter_name}</div>
               <div className="product-desc">
                        <p>{level.counter_description}</p>
                      </div>
                      <div className="product-info">
                                <ul className="list-inline">
                                {
                                   level.counter_timings == null ? null : <li className="author"><span style={{fontWeight:'bold'}}>Operational Timings</span>-<span className="text">{level.counter_timings}</span></li> 
                                 }
                                  {
                                   level.counter_timings == null ? null : <li className="author"><span>{level.meal_type}</span></li>
                                 }
                                </ul><br/>
                                {
                                 level.counter_status == 1 ? <div className="swin-btn-wrap"><a href={`/CounterMenu/${level.id}`} className="swin-btn"><span>View Menu</span></a></div> : null
                                }
                              </div>     
               </div>
            </div>
          </div>
            )
         }               */}
       
       
       {/* <div className="swin-sc swin-sc-title style-3 light" style={{marginTop:"70px"}}>
       {
            level_heading2.map(heading=>
              <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
            <img src={`assets/images/logo/${heading.level_image}`} className="heading_logo" alt="" style={{height:"118px"}}/>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12" style={{height:"118px",position:"relative",textAlign:"center"}}>
              <p className="title" style={{textAlign:"center",lineHeight:"2.5",display:"inline-block",verticalAlign:"middle"}}><span>{heading.level_name}</span></p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
              </div>
            </div>  
            )
          } 
          </div> */}
       
          {/* {
           level_two.map(level =>
            <div className="row diningmenu">
            <div className="col-md-4 col-sm-6 col-xs-12">
            <img src={`${this.state.assets_url}/uploads/${level.counter_logo}`}  alt="fooday" className="img img-responsive"/>
            </div>
            <div className="col-md-8 col-sm-6 col-xs-12">
               <div className="product-summary">
               <div className="title">{level.counter_name}</div>
               <div className="product-desc">
                        <p>{level.counter_description}</p>
                      </div>
                      <div className="product-info">
                                <ul className="list-inline">
                                  {
                                   level.counter_timings == null ? null : <li className="author"><span style={{fontWeight:'bold'}}>Operational Timings</span>-<span className="text">{level.counter_timings}</span></li> 
                                 }
                                  {
                                   level.counter_timings == null ? null : <li className="author"><span>{level.meal_type}</span></li>
                                 }
                                </ul><br/>
                                {
                                 level.counter_status == 1 ? <div className="swin-btn-wrap"><a href={`/CounterMenu/${level.id}`} className="swin-btn"><span>View Menu</span></a></div> : null
                                }
                              </div>     
               </div>
            </div>
          </div>
            )
         } */}
         
          
           </div>
         </div>
       </div>
     </section>

         <div className="container" style={{marginTop:'20px',marginBottom:'20px'}}>
           <div className="row">
             <div className="col-md-12">
               {/* <p style={{color:'red'}}>** On weekends a scaled down food program will be available. Please contact your Cafe manager for more details.</p> */}
             </div>
           </div>
         </div>

         {
           relatedoc.length == 0 ? null :  
            <div className="row bg-color padding-bottom-100 related_doc">
           <div className="container">
           <h3 className="res-title" style={{color:'#000',marginTop:'40px'}}>Related Document</h3>
           <div className="row mb-3">
           <div className="col-12">
           { relatedoc.map(doc=>
             
             <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
            <a href={`${this.state.assets_url}/uploads/${doc.file_name}`} target="_blank"><p className="des realtedoc">{doc.file_title}</p></a>
             </div>
               ) }
           </div>
           </div> 
          
           </div>
           </div> 
         }
   
     </>
  )};
  
};

export default Employee_dinning;